@value v-hackster, v-hacksterHover, v-white from '../../../styles/global_ui/colors.css';

.checkmark {
  padding: 2px;
  border: 2px solid transparent;
}

.hackster, .pro {
  background: v-hacksterHover;
  color: var(--staticWhite);
}

.verified {
  border: 2px solid v-hackster;
  background: var(--whiteToTransparent);
  color: var(--hackster);
}

.partner {
  background: var(--warning);
  color: var(--staticWhite);
}

.medium {
  padding: 1px;
  border-width: 1px;
}

.medium.verified {
  padding: 2px;
  svg {
    width: 8px;
    height: 8px;
  }
}

.small {
  padding: 1px;
  border-width: 1px;
}
